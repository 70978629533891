import * as React from 'react'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import visa from '../assets/visa_logo.min.svg'
import mastercard from '../assets/mastercard_logo.min.svg'

const course = 3.6
export default function Card ({ value, price, brand }) {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        backgroundImage: 'url(/render.jpg)',
        backgroundSize: 'cover',
        boxShadow: 1,
        borderRadius: 2,
        py: 2,
        px: 3,
        m: 2,
        minWidth: 300,
        minHeight: 190,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between'
        }}
      >
        <Box sx={{ color: 'text.secondary' }}>
          <Box sx={{ color: 'text.secondary', fontSize: 20, }}>${value} USD</Box>
          <Box
            sx={{ color: 'text.primary', fontSize: 28, fontWeight: 'medium' }}
          >
            {price * course} CAED
          </Box>
        </Box>

        <Box sx={{ color: 'text.secondary' }}>
          <Box
            component='img'
            sx={{ width: 64 }}
            alt={brand}
            src={brand === 'visa' ? visa : mastercard}
          ></Box>
        </Box>
      </Box>

      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          mt: 1
        }}
      >
        <Box
          component={TrendingUpIcon}
          sx={{ color: 'success.dark', fontSize: 16, verticalAlign: 'sub' }}
        />
        <Box
          sx={{
            color: 'success.dark',
            display: 'inline',
            fontWeight: 'medium',
            mx: 0.5
          }}
        >
          18.77%
        </Box>
        <Box sx={{ color: 'text.secondary', display: 'inline', fontSize: 12 }}>
          vs. last week
        </Box>
      </Box> */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'center',
          mt: 1
        }}
      >
        <Button variant='contained'>Add to Cart</Button>
      </Box>
    </Box>
  )
}
