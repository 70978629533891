import React, { useEffect, useState } from 'react'
import {
  Link,
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import Box from '@mui/material/Box'
import { motion } from 'framer-motion'
import Container from '@mui/material/Container'
import Header from './components/Header'
import Footer from './components/Footer'
import Card from './components/Card'

import { darkTheme, ligthTheme } from './themes'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './App.css'

function App () {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '94vh'
        }}
      >
        <CssBaseline />
        <Header />
        <main className='App'>
          <Box className='section hero'>
            <Container maxWidth='xl'>
              <Box className='section'>
                <h1 className='hero-title'>
                  Visa and Mastercard Prepaid Credit Card
                </h1>
                <h3 className='hero-subtitle'>
                  Buy online Crypto Dirham prepaid card
                </h3>
              </Box>
            </Container>
          </Box>

          <Container maxWidth='xl'>
            <Box className='section'>
              <h1 className='title'>What We Offer</h1>
              <h5 className='subtitle'>
                We offer virtual prepaid Visa and Mastercard bank cards that
                allow you to pay for goods and services anonymously online. We
                work worldwide so you can purchase virtual prepaid cards from
                anywhere in the world and use them to pay online on any websites
                that accept Mastercard or Visa prepaid cards.
              </h5>
            </Box>
          </Container>

          <Container maxWidth='xl'>
            <Box className='section'>
              <h1 className='title'>Virtual Prepaid Credit Cards</h1>
              <h5 className='subtitle'>
                Your cards will be delivered automatically after a successful
                payment.
              </h5>
              <Box className='card-variants' sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}>
                <Card value={5} price={15} brand={'visa'} />
                <Card value={10} price={20} brand={'mastercard'} />
                <Card value={20} price={35} brand={'visa'} />
                <Card value={50} price={70} brand={'mastercard'} />
                <Card value={100} price={130} brand={'visa'} />
                <Card value={200} price={250} brand={'mastercard'} />
                <Card value={500} price={600} brand={'visa'} />
                <Card value={1000} price={1100} brand={'mastercard'} />
              </Box>
            </Box>
          </Container>

          <Container maxWidth='xl'>
            <Box className='section'>
              <h1 className='title'>How to pay?</h1>
              <h5 className='subtitle'>
                Buy CAED currency directly from us using cryptocurrency. CAED=1
                AED. Then buy your prepaid card. 1 usd = 3.68 CAED. Use Metamask
                or Trust Wallet to buy prepaid card.
              </h5>
            </Box>
          </Container>

          <Container maxWidth='xl'>
            <Box className='section'>
              <h1 className='title'>What is CAED?</h1>
              <h5 className='subtitle'>
                CAED is crypto United Arab Emirates Dirham.
              </h5>
            </Box>
          </Container>
        </main>
      </Box>
      <Footer />
    </ThemeProvider>
  )
}

export default App
