import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

let scrollbar
document.addEventListener("DOMContentLoaded", function () {
  //The first argument are the elements to which the plugin shall be initialized
  //The second argument has to be at least a empty object or a object with your desired options
  // const navbar = document.querySelector('.navbar')

  scrollbar = window.OverlayScrollbars(document.querySelectorAll("body"), {
    className: "os-theme-light",
    scrollbars: {
      autoHide: "scroll",
    },
    callbacks: {
      onScroll: ev => {
        const y = scrollbar.scroll().position.y
        // console.log('scrollbar', y)
        if (y > 5) {
          // navbar.classList.add('active')
        } else {
          // navbar.classList.remove('active')
        }
                
        // if (y > 1000) {
        //     $('#scrollTop').addClass('active');
        // } else {
        //     $('#scrollTop').removeClass('active');
        // }
      }
    }
  });
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
