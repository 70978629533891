import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

function Copyright () {
  return (
    <Typography variant='body2' textAlign='center' color='text.secondary'>
      {new Date().getFullYear()}
      {' © YETI Ecosystem FZ-LLC'}
    </Typography>
  )
}

export default function StickyFooter () {
  return (
    <>
      <Box
        component='footer'
        sx={{
          py: 3,
          px: 2,
          // mt: 'auto',
          backgroundColor: theme => theme.palette.background.default
        }}
      >
        <Container maxWidth='x1'>
          <footer id='about' className=''>
            <Box className='container'>
              <Box className='row brandlogo' sx={{ display: 'flex', justifyContent: 'space-evenly', p: 5 }}>
                <Box
                  component='img'
                  sx={{ maxWidth: '9vw', maxHeight: '4rem' }}
                  alt='YETI VPN Logo'
                  src='/logo_text.png'
                ></Box>
                <Box
                  component='img'
                  sx={{ maxWidth: '9vw', maxHeight: '4rem' }}
                  alt='Dubai Internet City Logo'
                  src='/logo_dic.png'
                ></Box>
                <Box
                  component='img'
                  sx={{ maxWidth: '9vw', maxHeight: '4rem' }}
                  alt='in5 Tech Logo'
                  src='/logo_in5.png'
                ></Box>
              </Box>

              <Box className='row footerlinks' sx={{ display: 'flex', justifyContent: 'space-evenly', p: 5 }}>
                <Box className='footerlinksBlock'>
                  <h2 className='h5 mb-4'>Links</h2>
                  <Box className='d-flex'>
                    <ul className='list-unstyled  d-inline-block mb-0'>
                      <li className='nav-item'>
                        <a className='footer-link' href='#firstinfo'>
                          About
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='footer-link' href='#pricing'>
                          Pricing
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a className='footer-link' href='faq.html'>
                          FAQ
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className='footer-link'
                          target='_blank'
                          href='https://yeticoin.tech'
                          rel='noreferrer'
                        >
                          YETI Coin
                        </a>
                      </li>
                    </ul>
                  </Box>
                </Box>

                <Box className='footerlinksBlock'>
                  <h2 className='h5 mb-4'>Legal</h2>
                  <Box className='d-flex'>
                    <ul className='list-unstyled d-inline-block mb-0'>
                      <li className='mb-2'>
                        <a className='footer-link' href='/terms.html'>
                          Terms of Service
                        </a>
                      </li>
                      <li className='mb-2'>
                        <a className='footer-link' href='/privacy.html'>
                          Privacy Policy
                        </a>
                      </li>
                      {/* <!-- <li className="mb-2"><a className="footer-link" href="#">Contact us</a></li> */}
                      {/* <li className="mb-2"><a className="footer-link" href="#">Features</a></li> --> */}
                    </ul>
                  </Box>
                </Box>

                <Box className='footerlinksBlock'>
                  <h2 className='h5 mb-4'>Social</h2>
                  <ul className='list-unstyled mb-0'>
                    <li className='tg'>
                      {' '}
                      <a
                        className='footer-link'
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                        href='https://t.me/yetiecosystem'
                      >
                        <span>Telegram</span>{' '}
                      </a>{' '}
                    </li>
                    <li className='fb'>
                      {' '}
                      <a
                        className='footer-link'
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                        href='https://www.facebook.com/yetiecosystem'
                      >
                        <span>Facebook</span>{' '}
                      </a>{' '}
                    </li>
                    <li className='tw'>
                      {' '}
                      <a
                        className='footer-link'
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                        href='https://twitter.com/YetiEcosystem'
                      >
                        <span>Twitter</span>{' '}
                      </a>{' '}
                    </li>
                    <li className='reddit'>
                      {' '}
                      <a
                        className='footer-link'
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                        href='https://www.reddit.com/user/yeti_coin/?sort=hot'
                      >
                        <span>Reddit</span>{' '}
                      </a>
                    </li>
                  </ul>
                </Box>

                <Box className='footerlinksBlock'>
                  <h2 className='h5 mb-4'>Contact Us</h2>
                  <ul className='list-unstyled mb-3'>
                    <li className='mb-2 text-muted'>
                      In5 Tech, King Salman Bin Abdulaziz Al Saud Street
                    </li>
                    <li className='mb-2 text-muted'>
                      near Tram Station and Cordoba Residence, Dubai, UAE
                    </li>
                    {/* <!-- <li className="mb-2"><a className="footer-link" href="tel:619-851-4132">619-851-4132</a></li> --> */}
                    <li className='mb-2'>
                      <a
                        className='footer-link'
                        href='mailto:contact@yetivp.com'
                      >
                        contact@yetivp.com
                      </a>
                    </li>
                  </ul>
                </Box>
              </Box>

              <Box className='copyrights'>
                <Box className='container text-center py-4'>
                  <Copyright />
                </Box>
              </Box>
            </Box>
          </footer>
        </Container>
      </Box>
    </>
  )
}
