import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
})

export const ligthTheme = createTheme({
  palette: {
    mode: 'light',
  },
})